<template>
	<v-dialog
		v-model="show_bid_box"
		height="auto"
		width="1280"
		transition="slide-fade"
		hide-overlay
		light
	>
		<v-row no-gutters dense>
			<v-card min-height="100%" min-width="100%" class="my-card d-flex">
				<v-row no-gutters dense class="d-flex flex-column">
					<v-row no-gutters dense>
						<v-col cols="12" class="heading-sf20 pb-4">Company Information</v-col>
						<v-col cols="6" class="list-item-view">
							<div class="d-flex pb-2">
								<div class="list-detail-title">Company Name:</div>
								<div class="heading-rf15">{{ this.user_info.company_name }}</div>
							</div>
							<div class="d-flex pb-2">
								<div class="list-detail-title">Main Contact:</div>
								<div class="heading-rf15">{{ this.user_info.main_contact }}</div>
							</div>
							<div class="d-flex pb-2">
								<div class="list-detail-title">Email:</div>
								<div class="heading-rf15">{{ this.user_info.email }}</div>
							</div>
							<div class="d-flex pb-2">
								<div class="list-detail-title">Contact Number:</div>
								<div class="heading-rf15">{{ this.user_info.phone }}</div>
							</div>
							<div class="d-flex pb-2">
								<div class="list-detail-title">Title</div>
								<div class="heading-rf15">{{ this.user_info.title }}</div>
							</div>
							<div class="d-flex pb-2">
								<div class="list-detail-title">Address:</div>
								<div class="heading-rf15">{{ this.user_info.address }}</div>
							</div>
						</v-col>
						<v-col cols="6" class="pl-4">
							<div class="d-flex pb-2">
								<div class="list-detail-title">Account Number:</div>
								<div class="heading-rf15">{{ this.user_info.account_number }}</div>
							</div>
							<div class="d-flex pb-2">
								<div class="list-detail-title">Routing Number:</div>
								<div class="heading-rf15">{{ this.user_info.routing_number }}</div>
							</div>
							<div class="d-flex pb-2">
								<div class="list-detail-title">Product Categories:</div>
								<div class="heading-rf15">{{ this.user_info.categories }}</div>
							</div>
							<div class="d-flex pb-2">
								<div class="list-detail-title">Delivery Zones:</div>
								<div class="heading-rf15">{{ this.user_info.delivery_zone }}</div>
							</div>
							<div class="d-flex pb-2">
								<div class="list-detail-title">Max Delivery Range:</div>
								<div class="heading-rf15">{{ this.user_info.delivery_range }} (miles)</div>
							</div>
						</v-col>
						<v-col cols="12" class="heading-sf20 pt-6 pb-3">Authorize Users</v-col>
						<v-col cols="12">
							<v-simple-table>
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Fullname</th>
											<th class="text-left">Email</th>
											<th class="text-left">Phone</th>
											<th class="text-left">Role</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(item, index) in authorizeUser" :key="index">
											<td>{{ item.fullname }}</td>
											<td>{{ item.email }}</td>
											<td>{{ item.phone }}</td>
											<td>{{ item.role === 'AU' ? 'Authorize User' : 'Delivery Crew'}}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-col>

						<v-col cols="12" class="no-data text-center py-6" v-if="authorize_user.length == '0'">
							No Authorize users
						</v-col>
					</v-row>
					<v-row no-gutters dense align="stretch" class="d-flex">
						<v-col cols="12" class="d-flex justify-end mt-auto pt-4">
							<v-btn
								class="my-outline-btn mr-4"
								id="my_elevation"
								@click="show_bid_box = false"
								depressed
							>Close</v-btn>
						</v-col>
					</v-row>
				</v-row>
			</v-card>
		</v-row>
	</v-dialog>
</template>

<script>
import { distributorCompany } from '../../services/distributorservice';
export default {
	components: {
		AppTextBox: () => import("../../mycomponents/MyTextBox"),
		AppPhoneNumber: () => import("../../mycomponents/MyPhoneNumber"),
		AppUserEmail: () => import("../../mycomponents/UserEmail")
	},

	data() {
		return {
			show_bid_box: false,
			valid: true,
			user_info: {},
			authorize_user: []
		};
	},

	computed: {
		authorizeUser() {
			return this.authorize_user;
		}
	},

	methods: {
		async openCompanyView(id) {
			let data = await distributorCompany(id);	

			this.user_info.company_name = data.data.company.name;
			this.user_info.main_contact = data.data.company.main_contact;
			this.user_info.title = data.data.company.title;
			this.user_info.email = data.data.company.email;
			this.user_info.phone = data.data.company.phone === null 
								? null 
								: data.data.company.phone.length > 0 
									? data.data.company.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "($1)-$2-$3") 
									: null
								;
			this.user_info.address = data.data.company.address;
			this.user_info.account_number = data.data.company.account_number;
			this.user_info.routing_number = data.data.company.routing_number;
			this.user_info.address = data.data.company.address;

			this.user_info.delivery_zone = data.data.count_delivery_zone;
			this.user_info.delivery_range = data.data.max_delivery_range;

			this.user_info.categories = null;
			data.data.categories.forEach(category => {
				if(this.user_info.categories !== null){
					this.user_info.categories = this.user_info.categories + ', ' + category.name;
				}
				else {
					this.user_info.categories = category.name;
				}
			});

			this.authorize_user = [];
			if(data.data.authorize_user.length > '0') {
				data.data.authorize_user.forEach(user => {
					let ob = {
						fullname: user.distributor.fullname,
						email: user.distributor.email,
						phone: user.distributor.phone === null 
								? null 
								: user.distributor.phone.length > 0 
									? user.distributor.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "($1)-$2-$3") 
									: null
								,
						role: user.role
					}
					this.authorize_user.push(ob);
				});
			}



			this.show_bid_box = true;
		}
	}
};
</script>


<style lang="scss" scoped>
.list-item-view {
	border-right: 1px solid #dde2ee;
}

.list-detail-title {
	font-family: Inter-Regular;
	font-size: 15px;
	color: #7687a2;
	width: 200px;
}
</style>